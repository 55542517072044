const MakerArray = [
  {
    name: 'mouse',
    fname: 'マウスコンピュータ',
    url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0">マウス</a>',
    sales: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448077" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448077" height="1" width="1" border="0" alt=""/>〇</a>',
    outlet: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fe%2Fea5010000%2F" target="_blank" rel="nofollow noopenner"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" alt="" height="1" width="0" border="0">〇</a>',
    coupon: '',
  },
  {
    name: 'koubou',
    fname: 'パソコン工房',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" >',
    sales: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000276&type=3&subid=0" rel="nofollow noopener" target="_blank">〇</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000276&type=3&subid=0" />',
    outlet: '<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=13526&murl=https%3A%2F%2Fwww.pc-koubou.jp%2Fcategory%2F042203.html">〇</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.1&type=10">',
    coupon: '',
  },
  {
    name: 'dell',
    fname: 'デル',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10003522&type=3&subid=0" rel="nofollow noopener" target="_blank">デル</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10003522&type=3&subid=0" />',
    sales: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10003522&type=3&subid=0" rel="nofollow noopener" target="_blank">〇</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10003522&type=3&subid=0" />',
    outlet: '',
    coupon: '特にアクションはなく自動で適用されている。',
  },
  {
      name: 'lenovo',
      fname: 'Lenovo',
      url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=884764152" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=884764152" height="1" width="1" border="0">Lenovo</a>',
      sales: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448116" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448116" height="1" width="1" border="0" alt=""/>〇</a>',
      outlet: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887538317&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpoutlet%2F%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopenner"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887538317" alt="" height="1" width="0" border="0">〇</a>',
      coupon: '製品画面にあるものは表示される。会計時に入力',
  },
  {
      name: 'frontier',
      fname: 'フロンティア',
      url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886578503"  target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886578503" height="1" width="1" border="0">フロンティア</a>',
      sales: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886332066&vc_url=https%3A%2F%2Fwww.frontier-direct.jp%2Fdirect%2Fc%2Fcsaleinfo%2F" rel="nofollow noopener" target="_blank">〇</a><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886332066" alt="" height="1" width="1"/>',
      outlet: '',
      coupon: '',
  },
  {
    name: 'fujitsu',
    fname: '富士通',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000156&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><IMG border=0 width=1 height=1 src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000156&type=3&subid=0" >',
    sales: '',
    outlet: '<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=2543&murl=https%3A%2F%2Ffmv.fccl.fujitsu.com%2Fshop%2Fpc%2Fwakeari" target="_blank" rel="nofollow noopenner">〇</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.1&type=10">',
    coupon: '特別クーポン【SPLSJ】で通常クーポンから更に割引。詳細は<a href="/fujitsu/">こちら</a>',
  },
  {
      name: 'hp',
      fname: 'HP',
      url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.507&type=3&subid=0" target="_blank" rel="nofollow noopener">HP</a><IMG border=0 width=1 height=1 src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.507&type=3&subid=0" >',
      sales: '<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fcampaign%2Fpersonal%2F" target="_blank" rel="nofollow noopenner">〇</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10">',
      outlet: '<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fcampaign%2Fpersonal%2Fothers%2Foutlet%2F" target="_blank" rel="nofollow noopenner">〇</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10">',
      coupon: '<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fcampaign%2Fpersonal%2F" rel="nofollow noopener" target="_blank">HPのキャンペーンコレクション</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10"/>',
  },
  {
      name: 'nec',
      fname: 'NEC',
      url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0">NEC</a>',
      sales: '',
      outlet: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fshop%2Foutlet%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopenner"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" alt="" height="1" width="0" border="0">〇</a>',
      coupon: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448107" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448107" height="1" width="1" border="0" alt=""/>NEC広告限定クーポン</a>を事前に取得しておくよい。',
  },
  {
      name: 'dynabook',
      fname: 'Dynabook',
      url: '<a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10000411&type=3&subid=0" target="_blank" rel="nofollow noopener">Dynabook</a><IMG border=0 width=1 height=1 src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10000411&type=3&subid=0" >',
      sales: '',
      outlet: '<a href="https://linksynergy.jrs5.com/deeplink?id=xsv0FDnJs1M&mid=36508&murl=https%3A%2F%2Fdynabook.com%2Fdirect%2Foutlet.html" target="_blank" rel="nofollow noopenner">〇</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.1&type=10">',
      coupon: '<a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001860&type=3&subid=0" rel="nofollow noopener" target="_blank">限定プレミアサイト</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001860&type=3&subid=0" />から購入で特別割引適用【ID:dyna204cls, PASS:T8Y7GRSV】',
  },
  {
      name: 'microsoft',
      fname: 'Microsoft',
      url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=791390.9&type=3&subid=0" target="_blank" rel="nofollow noopener">Microsoft</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=791390.9&type=3&subid=0" >',
      sales: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=887817.286&type=3&subid=0" rel="nofollow noopener" target="_blank">〇</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=887817.286&type=3&subid=0" />',
      outlet: '',
      coupon: '',
  },
  {
    name: 'asus',
    fname: 'ASUS',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=858354.11&type=3&subid=0" target="_blank" rel="nofollow noopener">ASUS</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.11&type=3&subid=0" >',
    sales: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=623694.61&type=3&subid=0" rel="nofollow noopener" target="_blank">〇</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=623694.61&type=3&subid=0" />',
    outlet: '',
    coupon: '',
  },
  {
    name: 'seven',
    fname: 'SEVEN',
    url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899662" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899662" height="1" width="1" border="0">SEVEN</a>',
    sales: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448087" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448087" height="1" width="1" border="0" alt=""/>〇</a>',
    outlet: '',
    coupon: '',
  },
  {
    name: 'tsukumo',
    fname: 'TSUKUMO',
    url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899687" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899687" height="1" width="1" border="0">TSUKUMO</a>',
    sales: '',
    outlet: '',
    coupon: '',
  },
];
  
  export const getMaker = maker => {
    return MakerArray.find(elem => elem.name === maker);
  };
  
  export default MakerArray;
  