import React from "react"
import ScrollTable from './scrollTable';
import BasicTable from './basicTable';
import MakerArray from './datas/makerSaleAll';
import { Link } from "gatsby"

export default function MakerSaleTable(props) {
    return (
        <BasicTable>
        <thead>
          <tr>
            <th>メーカー</th>
            <th>セール</th>
            <th>アウトレット</th>
            <th>クーポン</th>
          </tr>
        </thead>
        <tbody>
        {MakerArray.map((maker, i) => {
          return (
            <tr key={maker.name}>
              <td><span dangerouslySetInnerHTML={{__html: maker.url}} /></td>
              <td  align="center"><span dangerouslySetInnerHTML={{__html: maker.sales}} /></td>
              <td  align="center"><span dangerouslySetInnerHTML={{__html: maker.outlet}} /></td>
              <td><span dangerouslySetInnerHTML={{__html: maker.coupon}} /></td>
            </tr>
          )
        })}
        </tbody>
    </BasicTable>
    );
};
